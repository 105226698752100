
export const androidDispatchers = {
    WebViewJsDispatcher:{
        name:"WebViewJsDispatcher",
        comment:"全局单例类"
    },
    Window:{
        name:"Window",
        comment: "窗口类单例，可以用来侦听窗口的dismiss事件3"
    },
    webViewJs:{
        name:"webViewJs",
        comment:"当前窗口被注入的android webviewjs"
    }
}